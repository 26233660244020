@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.job-tag {
  letter-spacing: 0.5px;
  /* font-weight: bold; */
}

.circle {
  @apply transition-all;
  transition: stroke-dashoffset 1s linear;
}

button:hover {
  cursor: pointer;
}

.rounded-full.animate-spin {
  height: 1.5rem;
  width: 1.5rem;
}
.rejection-reasons {
  padding: 2rem 1rem;
  background-color: aliceblue;
}
@media (min-width: 1024px) {
  .rejection-reasons {
    min-width: 400px;
  }
}

.slide-in-left {
  transition-property: transform;
  transition-duration: 0.3s; /* Adjust as needed */
  transform: translateX(0);
}

.slide-out-left {
  transition-property: transform;
  transition-duration: 0.3s; /* Adjust as needed */
  transform: translateX(-100%);
}

.rating {
  padding: 1rem;
  margin: 0;
}

.rating:not(:first-child) {
  border-top: 1px solid #eee;
}

.button {
  display: flex;
  align-items: center;
}

.rti--input {
  max-width: 150px;
  border-radius: 5px;
}

.hamburger-inner-menu {
  height: calc(100vh - 5.26rem);
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-dropdown-container {
  position: relative;
}
.custom-dropdown-container .custom-dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #f9fafb;
}
.custom-dropdown-menu {
  position: absolute;
  top: 41px;
  left: -17px;
  width: 304px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: -1px 5px 5px 0px #0000001a;
  height: 200px;
  overflow-y: auto;
  z-index: 9999;
}
.custom-dropdown-menu li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 10px;
}
.custom-dropdown-menu li:hover {
  background: #e0f2fe;
  cursor: pointer;
}
.custom-dropdown-menu li img {
  margin-right: 5px;
}
@media (max-width: 575px) {
  .custom-dropdown-menu {
    left: 0 !important;
  }
}

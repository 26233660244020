.calander-custom-style {
  div {
    div {
      div {
        div {
          div {
            button {
              padding: 0;
              display: flex;

              span {
                color: rgb(107, 114, 128);

                &:hover:not(.disabled) {
                  color: black;
                }
                &.disabled {
                  color: rgb(107, 114, 128, 0.2);
                }
              }
            }
          }
        }
      }
    }
  }
}

.skills-input,
.rti--container {
  max-height: 106px;
  overflow-y: scroll;
  --rti-main: var(--color-primary-400) !important;
  --rti-tag: var(--color-primary-400) !important;
}

.rti--input {
  border: 1px solid var(--color-primary-400) !important;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 0.875rem;
  width: 100%;
  margin-bottom: 0.5rem;
}

.rti--input:focus {
  border-color: var(--color-primary-500) !important;
  --tw-ring-color: var(--color-primary-500) !important;
  outline: none; /* Removes the default outline */
}

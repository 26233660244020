/* Advanced Hover Effects */
.hover-highlight:hover {
  background-color: #f1f5f9;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.hover-color:hover {
  color: #2563eb;
  transition: color 0.3s ease-in-out;
}

.focus-ring:focus {
  outline: 2px solid #2563eb;
  outline-offset: 4px;
}

.animate-fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.icon-wrapper svg {
  width: 1.25rem;
  height: 1.25rem;
}

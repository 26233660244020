@keyframes slideUp {
  0% {
    transform: translateY(50%) translateX(30%);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(50%) translateX(30%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes smoothScaleFadeIn {
  0% {
    transform: translateY(100%) translateX(10%) scale(0.95);
    opacity: 0;
  }
  50% {
    transform: translateY(0) translateX(0) scale(1.02);
    opacity: 1;
  }
  100% {
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;
  }
}

.smooth-scale-fade-in {
  animation: smoothScaleFadeIn 0.7s ease-in-out forwards;
}

@keyframes smoothScaleFadeOut {
  0% {
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(0) translateX(0) scale(1.02);
    opacity: 1;
  }
  100% {
    transform: translateY(100%) translateX(10%) scale(0.95);
    opacity: 0;
  }
}

.smooth-scale-fade-out {
  animation: smoothScaleFadeOut 0.7s ease-in-out forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slide-up {
  animation: slideUp 0.7s ease-out forwards;
}

.slide-down {
  animation: slideDown 0.7s ease-out forwards;
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

.fade-out {
  animation: fadeOut 1s ease-in-out forwards;
}

.notification-container {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}
/* Arrow pointing downwards */
.message-bubble::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(128, 90, 213, 1); /* Same color as the bubble */
}

/* 
.notification {
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.notification.default {
  background-color: #333;
}

.notification.success {
  background-color: #4caf50;
}

.notification.error {
  background-color: #f44336;
}

.notification.warning {
  background-color: #ff9800;
}

.notification.info {
  background-color: #2196f3;
} */

.header-gradient {
  background: linear-gradient(135deg, #007bff, #6c757d);
  color: white;
  padding: 50px 0;
}

.header-title {
  font-size: 2.5rem;
  font-weight: 900;
}

.header-description {
  font-size: 1.25rem;
  color: #f8f9fa;
}

.test-card {
  border-radius: 15px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  border: 1px solid #dee2e6;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.test-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 1.5rem;
  font-weight: 700;
}

.card-text {
  font-size: 1rem;
  color: #6c757d;
}

.icon-box {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 50%;
}

.start-btn {
  font-size: 1.25rem;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #28a745;
  color: #fff;
  font-weight: 700;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.start-btn:hover {
  background-color: #218838;
  color: #fff;
}

.status-completed {
  color: #28a745;
  font-size: 1.5rem;
  font-weight: 700;
}

.status-not-started {
  color: #dc3545;
  font-size: 1.5rem;
  font-weight: 700;
}

.additional-info {
  font-size: 1.1rem;
  color: #6c757d;
}

@media (min-width: 768px) {
  .test-card {
    height: 100%;
  }
}

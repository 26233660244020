input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 0px;
  background: transparent;
  pointer-events: auto;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--color-primary-700);
  cursor: pointer;
  pointer-events: auto;
  border: 2px solid white;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--color-primary-700);
  cursor: pointer;
  pointer-events: auto;
  border: 2px solid white;
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--color-primary-700);
  cursor: pointer;
  pointer-events: auto;
  border: 2px solid white;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
